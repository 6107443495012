import {onFind} from "@elements/init-modules-in-scope";
import {find,on} from "@elements/dom-utils";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

// Todo: Add common JS that is shared between all page types here

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as affix from '@elements/affix';

let affixNavbar = find('.js-affix-navbar');
let options = {mediaQuery: '(max-width: 767px)'};
if (affixNavbar && affixNavbar.getAttribute('data-affix-navbar-on-desktop') === 'true'){
    options = {};
}
affix.init(options,{
    base: '.js-affix-navbar',
    placeholder: '.js-affix-navbar__placeholder',
    container: '.js-affix-navbar__container'
});

import "bootstrap/js/dist/collapse";
import * as cookiePermissions from "@elements/cookie-permissions/bootstrap-5";
cookiePermissions.init();

import * as infoBox from './info-box';
infoBox.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as acceptCookieService from './accept-cookie-service';
acceptCookieService.init();

import * as loadIframeWithConsent from './load-iframe-with-consent';
loadIframeWithConsent.init();

import * as message from './message';
message.init();

import * as focusOnModalShow from './focusOnModalShow';
focusOnModalShow.init();

import * as aiConcierge from './ai-concierge';
aiConcierge.init();

import * as hashCash from './hash-cash';
hashCash.init();

import * as accessiway from './accessiway';
accessiway.init();

import * as scrollToRight from './scroll-to-right';
scrollToRight.init();

import * as cookieFirstOverlay from './cookie-first-overlay';
cookieFirstOverlay.init();