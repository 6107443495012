"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import loadScript from "@elements/load-script";
import {find, findAll, on} from "@elements/dom-utils";

export function init () {
    on("cf_init", function() {
        CookieFirst.consent && CookieFirst.consent.advertising && cb();
    }, window)

    on('cf_consent', (event) => {
        event.detail.advertising && cb();
    }, window);

    let previousConsent = false;
    on("cf_consent_loaded", function (event) {
        console.log("previous consent", event.detail)
        previousConsent = event.detail;
    }, window);

    console.log("registering concierge cleanup interval");
    const conciergeCleanupInverval = setInterval(() => {
        if(findAll(".aic_floating-icon").length > 1) {
            // remove one of the icons, if one gets added later due to window.onload that is in the imported script
            // we also need the manual include here due to the fact that the script can be loaded after the window.onload event finishes
            find(".aic_floating-icon").remove();
        }
    }, 100);

    function cb() {
        console.log("concierge cb");
        onFind(".js-open-chat-link", function (chatLink) {
            console.log("found open-chat-link");
            loadScript('https://concierge.goodguys.ai/client/6554cac3892c9d5bfdb43388/ai_concierge.js').then(function () {
                loadConciergeIcon();
            })
        });
    }

    function loadConciergeIcon() {
        let launch_icon = document.createElement("div");
        launch_icon.classList.add("aic_floating-icon");
        launch_icon.onclick = function () {
            startConversation();
        };

        document.body.appendChild(launch_icon);
    }
}